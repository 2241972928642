<template>
    <div id="Shop">
        <SubHeader title="상점" :link="'/'"></SubHeader>
        <Advertisement></Advertisement>
        <div class="flex-end-center mt-2 px-3">
            <router-link to="/cart" class="flex-start-center border rounded small ps-2 pe-2 py-1">
                <div class="position-relative">
                    <i class="fal fa-shopping-cart small me-2 border-end pe-2"></i>
                    <span v-if="cart_item_length != 0" class="bg-danger position-absolute top-0 end-0 me-2 text-white fs-px-8 lh-1 sm_badge">{{cart_item_length}}</span>
                </div>
                장바구니
            </router-link>
        </div>
        <ul class="shop_list p-3">
            <li class="shop_item" v-for="(item, index) in shop_item" :key="index">
                <div class="position-relative ratio ratio-1x1">
                    <div class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center">
                        <img :src="item.image" alt="" width="100%" />
                    </div>
                </div>
                <div class="p-2 border-end border-start border-bottom border-gray-relative-200">
                    <div class="fs-px-13 text-truncate">{{ item.name }}</div>
                    <div class="fs-px-14">
                        <b>{{ CheckPrice(item.price) }} </b>원
                        <small v-if="showDiscount(item.discount)">(할인율 {{ item.discount }}%)</small>
                    </div>
                    <div class="flex-between-center py-3">
                        <i class="fas fa-caret-left px-2" @click="AmountDown(index)"></i>
                        <input type="number" v-model="amounts[index]" class="form-control text-center"/>
                        <i class="fas fa-caret-right px-2" @click="AmountUp(index)"></i>
                    </div>
                    <div>
                        <ul>
                            <li class="flex-between-center">
                                <small class="text-gray-relative-600">판매금액</small>
                                <small>{{ CheckPrice(item.price * amounts[index]) }}원</small>
                            </li>
                            <li class="flex-between-center" v-if="showDiscount(item.discount)">
                                <small class="text-gray-relative-600">할인금액</small>
                                <small>{{CheckPrice(((item.price * item.discount) / 100) * amounts[index])}}원</small>
                            </li>
                            <li class="flex-between-center border-top mt-2 pt-2">
                                <small class="text-gray-relative-600">결제금액</small>
                                <small class="fw-bold">{{CheckPrice(item.price * amounts[index] -(item.price * amounts[index] * item.discount) / 100)}}원</small>
                            </li>
                        </ul>
                    </div>
                    <div class="flex-column flex-xs-row flex-between-center mt-1">
                        <button class="btn btn-sm fs-px-13 w-100 text-center border border-main text-main thick mt-1 rounded me-0 me-xs-1" @click="cart(index)">
                            장바구니 담기
                        </button>
                        <button class="btn border-0 btn-sm fs-px-13 pay_btn w-100 text-center btn-main thick mt-1 rounded" @click="buy(index)">
                            구매하기
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="show_cart_popup" class="cart_popup position-fixed top-0 start-0 w-100 h-vh-100 zindex-100">
            <div class="dimm position-absolute top-0 start-0 w-100 h-100" @click="show_cart_popup = false"></div>
            <div class="bg-body position-absolute top-50 start-50 translate-middle shadow-lg w-px-250 pb-4 text-center">
                <div class="text-end">
                    <button class="btn btn-sm" @click="show_cart_popup = false"><i class="fal fa-times"></i></button>
                </div>
                <p class="small mb-2">상품이 장바구니에 담겼습니다.</p>
                <router-link to="/cart" class="btn text-main border border-main btn-sm">장바구니 바로가기 <i class="fal fa-chevron-right"></i></router-link>
            </div>
        </div>
        <BuyPopup ref="BuyPopup" :item_info="select_item"></BuyPopup>
    </div>
</template>

<script>
const CryptoJS = require('crypto-js');

import SubHeader from '@/components/common/SubHeader.vue';
import Advertisement from '@/components/common/Advertisement.vue';
import BuyPopup from '@/components/popup/Buy_Gift.vue';
export default {
    components: {
        SubHeader,
        Advertisement,
        BuyPopup,
    },
    data() {
        return {
            select_item: {},
            shop_item: [],
            cart_item_length: 0,
            discount_r: 5,
            amounts: [],
            show_cart_popup: false,
        };
    },
    mounted() {
        this.MyCartInfo();
        this.GetShopItem();
    },
    methods: {
        CheckPrice(price) {
            const amount = price * 1;
            return amount.toLocaleString();
        },
        GetShopItem() {
            const code = this.code;
            
            const body = { code };
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/GetShopItem', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);

                        this.shop_item = body.list;
                        this.InitAmount();
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        MyCartInfo() {
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/GetMyCartInfo', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        
                        this.cart_item_length = body.count;
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        buy(item, type, amount) {
            const login = this.$store.state.login;
            if (login == true) {
                this.$refs.BuyPopup.pp = true;
                this.select_item = item;
                this.select_item.type = type;
                this.select_item.amount = amount;
            } else {
                this.$router.push({ path: '/signin' });
            }
        },
        cart(index){
            const item = this.shop_item[index];
            const count = this.amounts[index];
            const item_code = item.code;
            
            
            const body = { item_code ,count};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/AddCart', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.MyCartInfo()
                        this.InitAmount();
                        this.show_cart_popup = true
                        
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
            
        },
        buy(index){
            const item = this.shop_item[index];
            const count = this.amounts[index];
            const item_code = item.code;
            
            
            const body = { item_code ,count};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/AddCart', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        
                        this.$router.push({path : '/cart'})
                        
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
            
        },
        InitAmount() {
            this.amounts = [];
            for (let i = 0; i < this.shop_item.length; i++) {
                this.amounts.push(1);
            }
        },
        AmountUp(index) {
            this.amounts[index] += 1;
            this.$forceUpdate();
        },
        AmountDown(index) {
            if (this.amounts[index] > 1) {
                this.amounts[index] -= 1;
            }
            this.$forceUpdate();
        },
        showDiscount(discount) {
            return Number(discount) !== 0 ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.shop_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .shop_item {
    width: 48%;
    margin-bottom: 30px;
    .gift_btn {
      position: absolute;
      z-index: 1;
      width: 42px;
      height: 42px;
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 336px) {
  .shop_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .shop_item {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}

.ratio-1x1 {
  --bs-aspect-ratio: 45% !important;
}
</style>
