<template>
    <div id="Cart">
        <SubHeader title="장바구니" :link="'/shop'" :bookmark="false" :alert="false"></SubHeader>
        <div class="mt-2 px-3 last-border-0 pb-10 mb-7">
            <div class="my-4 border-bottom" v-for="(item,index) in list" :key="index">
                <div class="mx-4 d-flex flex-column flex-sm-row mb-4">
                    <div class=" mx-auto">
                        <div class="position-relative">
                            <div class="bg-gray-200 d-flex align-items-center justify-content-center text-center">
                                <img :src="item.image" alt="" width="100%" style="max-width: 500px" />
                            </div>
                        </div>
                        <div class="p-2 text-center">
                            <div class="fs-px-14 mb-2">
                                <b>{{item.name}}</b>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mx-auto">
                        <div class="p-2 pb-0 text-center ms-0 ms-sm-4">
                            <div class="flex-between-center px-2">
                                <span class="fs-px-14 text-muted">구매매수 </span>
                                <div class="flex-end-center">
                                    <div class=" rounded border">
                                        <i class="fs-px-13 fal fa-minus px-2" @click="AmountDown(index)"></i>
                                        <input type="number" v-model="item.amount" class="border-0 bg-gray-relative-100 text-center w-px-50 text-gray-relative-900"/>
                                        <i class="fs-px-13 fal fa-plus px-2" @click="AmountUp(index)"></i>
                                    </div>
                                    <div>
                                        <i class="fs-px-13 fal fa-trash text-danger ms-2" @click="Remove(index)"></i>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="flex-between-center px-2">
                                <span class="fs-px-14 text-muted">구매금액</span>
                                {{ CheckPrice(item.amount * item.price) }} 원
                            </div>
                            <div class="flex-between-center px-2">
                                <span class="fs-px-14 text-muted">할인금액 </span>{{CheckDiscount(item.amount,item.price,item.discount)}}원
                            </div>
                            <div class="flex-between-center cutting_line border-gray-200 mt-2 pt-2 bg-gray-relative-100 pb-2 px-2 border-bottom">
                                <span class="fs-px-14 text-muted">결제금액</span>
                                <b>{{TotalValue(item.amount,item.price,item.discount)}}원</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-fixed bottom-0 start-50 translate-middle-x w-100 border-top p-3 bg-body shadow-lg max-w-px-768">
            <h6 class="mb-3">총 결제금액</h6>
            <div class="mx-4">
                <div class="flex-between-center px-2">
                    <span class="fs-px-14 text-muted">총 구매금액</span>
                    {{ totalPrice }} 원
                </div>
                <div class="flex-between-center px-2">
                    <span class="fs-px-14 text-muted">총 할인금액</span>
                    {{ totalDiscount }} 원
                </div>
                <div class="flex-between-center cutting_line mt-2 pt-2 px-2 border-gray-relative-400">
                    <span class="fs-px-14 text-muted">총 결제금액</span>
                    <span class="fw-bold fs-px-18">{{ totalValue }} 원</span>
                </div>
            </div>
            <div class="mt-3 btn btn-main text-white w-100 thick" @click="Purchase()">
                구매하기
            </div>
        </div>
    </div>
</template>

<script>

const exactMath = require('exact-math');
const CryptoJS = require('crypto-js');

import SubHeader from '@/components/common/SubHeader.vue';
export default {
    components: {
        SubHeader,
    },
    data() {
        return {
            list: [
                
            ],
            total_price: 0,
            total_discount: 0,
            total_value: 0,
        };
    },
    computed:{
        totalPrice() {
            return this.list.reduce((total, item) => {
                return total + (item.amount * item.price);
            }, 0).toLocaleString();
        },
    
        totalDiscount() {
            return this.list.reduce((total, item) => {
                const totalItemPrice = item.amount * item.price;
                const itemDiscount = totalItemPrice * (item.discount / 100);
                return total + itemDiscount;
            }, 0).toLocaleString();
        },
    
        totalValue() {
            return this.list.reduce((total, item) => {
                const totalItemPrice = item.amount * item.price;
                const itemDiscount = totalItemPrice * (item.discount / 100);
                return total + (totalItemPrice - itemDiscount);
            }, 0).toLocaleString();
        }
    },
    mounted(){
        this.$EventBus.$emit('HideNav');
        this.GetMyCartList()
    },
    destroyed() {
        this.$EventBus.$emit('ShowNav');
    },
    methods: {
        GetMyCartList() {
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/GetMyCartList', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        const e_body = res.data.body;
                        const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                        const d_res = bytes.toString(CryptoJS.enc.Utf8);
                        const body = JSON.parse(d_res);
                        
                        this.list = body.list;
                        // this.cart_item_length = body.count;
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        Purchase(){
            const list = this.list;
            const body = {list};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/Purchase', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.$router.push('/shop-payment');
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
            
        },
        AmountUp(index) {
            this.list[index].amount =this.list[index].amount*1 + 1;
            this.$forceUpdate();
        },
        AmountDown(index) {
            if (this.list[index].amount > 1) {
                this.list[index].amount = this.list[index].amount*1 - 1;
            }
            this.$forceUpdate();
        },
        CheckPrice(price) {
            const amount = price * 1;
            return amount.toLocaleString();
        },
        CheckDiscount(amount, price, discount) {
            if (amount == undefined) {
                if (price == undefined) {
                    return false;
                }
            }

            const total = exactMath.mul(amount, price);
            const p_discount = exactMath.mul(total, discount, 0.01);
            
            return p_discount.toLocaleString();
        },
        TotalValue(amount, price, discount) {
            if (amount == undefined) {
                if (price == undefined) {
                    return false;
                }
            }

            const total = exactMath.mul(amount, price);
            const p_discount = exactMath.mul(total, discount, 0.01);
            const total_value = exactMath.sub(total, p_discount);
            
            this.total = total_value;
            return total_value.toLocaleString();
        },
        Remove(index){
            const code = this.list[index].code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            
            this.$http.post('/front/shop/RemoveCart', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.GetMyCartList()
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
            
        }
    },
    
};
</script>
