<template>
<div class="mh-vh-100 flex-center-center flex-column text-center">
    <h5 class="text-center fw-bold mb-3">결제 완료하였습니다.</h5>
    <div class="p-3">
        <img src="@/assets/img/firecracker.png" alt="" height="100" class="mb-4" />
    </div>
    <div class="position-absolute bottom-0 start-0 w-100 p-3">
        <button class="btn btn-main thick w-100 border-0 mb-3">
            <a :href="link" class="d-block"> 내역 확인하기 </a>
        </button>
    </div>
</div>
</template>

<script>
const CryptoJS = require('crypto-js');

export default {
    data() {
        return {
            pp: false,
            off: false,
            timeout_id: null,
            link: '/mypage/history',
            // order : '202411111054458997',
            order : this.$store.state.order
        };
    },
    components: {},
    mounted() {
        this.$EventBus.$emit('HideNav');
        this.OrderSuccess();
    },
    destroyed() {
        this.$EventBus.$emit('ShowNav');
    },
    methods: {
        OrderSuccess(){
            const order_no = this.order;
            const body = {order_no};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/front/shop/SuccessOrder', { req }).then((res) => {
                if (res.status == 200) {
                    if (res.data.code == '200') {
                        this.$store.dispatch('RESETORDER');
                    }else if (res.data.code == '100') {
                        this.$router.replace(this.link);
                    } else if (res.data.code == '9999') {
                        this.$store.dispatch('SETLOGOUT').then(() => {
                            this.$router.push({ path: '/signin' });
                        });
                    }
                }
            });
        },
        
    },
};
</script>
<style lang="scss" scoped>
* {
  word-break: keep-all;
}
</style>
